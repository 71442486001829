
.elTableHeader {
  border: "5px solid #000000" !important;   
  font-family: "Times New Roman"; 
  font-size: 12px; 
  font-weight: bold !important;
  height: 40px;
  text-align: center;  
  background:#1A73E8;
  background-color:#1662C4;
  background-image: none !important;
  color:#000000;
  
 }


 .elTableEvenRow {
    color:#000000;
    background-color: #e0ffff;
    text-align: center;
    font-size: 10px;
    height: 40px;
    font-weight: bold !important;
    border: "5px solid #000000";   
 }

 .elTableOddRow {
    color:#000000;
    background-color: #f5f5dc;
    text-align: center;
    font-size: 10px;
    height: 40px;
    font-weight: bold !important;
    border: "5px solid #000000";  
 }

 .elTableHeaderCell {
    background:#1A73E8;
    color:#ffffff;    
    width :"25%";
    padding: "10px";
 }

 .gold-border {
   border: 3px solid gold;
 }

 

 .elTableCell {
    text-align: center;
 }
 
 