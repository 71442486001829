

.vLineSolar {
  position: absolute;
  border-left: 6px solid rgba(255, 153, 0);
  height: 4rem;
  margin-left: 2.5rem;
  top: 9rem;
}

.hLineSolar {
    position: absolute;
    background-color: rgba(255, 153, 0);
    width: 25%;
    height: 6px;
    left: 3rem;
    top: 12.75rem;
  }
  
  .vLineGrid {
    position: relative;
    border-left: 6px solid rgb(34, 119, 231);
    height: 5.75rem;
    margin-left: 10.5rem;
    top: -9.25rem;
  }
  
  .hLineGrid {
      position: relative;
      background-color: rgb(34, 119, 231);
      width:5rem;
      height: 6px;
      left: 5.5rem;
      top: -3.5rem;
    }

    .vLineDG {
      position: relative;
      border-left: 6px solid rgba(123, 128, 154);
      height: 5.75rem;
      margin-left: -4.5rem;
      top: -9.25rem;
    }
    
    .hLineDG {
        position: relative;
        background-color: rgba(123, 128, 154);
        width:4.5rem;
        height: 6px;
        left: -4.5rem;
        top: -3.5rem;
      }
  

  
  .bubbleSolar {
    position: absolute;
    width: 8px; /* Adjust the size of the bubble */
    height: 8px; /* Adjust the size of the bubble */
    border-radius: 50%;
    background-color: rgb(250, 166, 93);
    animation: moveSolarBubble 1s linear infinite; 
  }

  .bubbleGrid {
    position: absolute;
    width: 8px; /* Adjust the size of the bubble */
    height: 8px; /* Adjust the size of the bubble */
    border-radius: 50%;
    background-color: rgb(34, 119, 231);
    animation: moveGridBubble 1s linear infinite; 
  }
  
  .bubbleDG {
    position: absolute;
    width: 8px; /* Adjust the size of the bubble */
    height: 8px; /* Adjust the size of the bubble */
    border-radius: 50%;
    background-color: rgba(123, 128, 154);
    animation: moveDGBubble 1s linear infinite; 
  }

 

@keyframes moveSolarBubble {
  0% { top: calc(40%); left: calc(25%); }
  50% { top: calc(49.5%); left: calc(25%); }
  100%   { top: calc(49.5%); left: calc(50%); }
}

@keyframes moveGridBubble {
  0% { top: calc(75%); left: calc(35%); }
  50% { top: calc(75%); left: calc(49%); }
  100%   { top: calc(50%); left: calc(49%); }
}

@keyframes moveDGBubble {
  0% { top: calc(75%); left: calc(65%); }
  50% { top: calc(75%); left: calc(49%); }
  100% { top: calc(50%); left: calc(49%); }
}

  /* @keyframes moveGridBubble {
    0% {
      top:  -6rem;
      left: 4rem;
    }
    100% {
      top:  -10rem;
      left: 8rem;
    }
  } */

  /* @keyframes moveDGBubble {
    0% {
      top:  -5rem;
      left: 0rem;
    }

    100% {
      top:  -10rem;
      left: -3rem;
    }
} */



