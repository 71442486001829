.select-input-styles {
    padding: 12px 0;   
}

.select-input-styles svg {
    color:black;
    background-color: aquamarine;
    display: block;
    width: 2em;
    height: 2em;
    top: calc(50% - 1em);
}