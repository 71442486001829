
.elTableHeader { 
   font-family: "sans-serif"; 
   font-size: 14px; 
   font-weight: bold !important;
   height: 40px;
   text-align: center;  
   background-color:#ffffe7;
   background:#ffffe7;
   color:#000000;
   background-image: none;
   white-space: nowrap;       
   min-width:  100px;
  }
 
 
  .elTableEvenRow {
     color:#000000;
     background-color: #e0ffff;       
     text-align: center;
     font-size: 10px;
     height: 40px;
     font-weight: bold !important;
  }
 
  .elTableOddRow {
     color:#000000;
     background-color: #f5f5dc;
     text-align: center;
     font-size: 10px;
     height: 40px;
     font-weight: bold !important;
  }
 
  .elTableHeaderCell {
    background-color:#ffffe7;
    background:#ffffe7;
    color:#000000;
    white-space: nowrap;       
    min-width:  100px;
    border: 1px solid darkolivegreen !important;      
     /* font-family: sans-serif,Arial; 
     font-size: 12px; 
     font-weight: bold !important;
     height: 40px;
     text-align: center;   */
     
  }
 
  .elTableCell {
     text-align: center;    
     font-size: 12px;    
     font-weight: bold !important;
     white-space: nowrap;
     border: 0.1px solid darkolivegreen !important;
  }
  
  